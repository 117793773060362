<template>
  <div class="layout-wrapper layout-1">
    <div class="layout-inner">
      <!-- <app-layout-navbar /> -->

      <div class="layout-container">
        <!--  <app-layout-sidenav /> -->

        <div class="layout-content">
          <div class="router-transitions">
            <router-view />
          </div>

          <!-- <app-layout-footer /> -->
        </div>
      </div>
    </div>
    <div class="layout-overlay" @click="closeSidenav"></div>
  </div>
</template>

<script>
/* import LayoutNavbar from './LayoutNavbar'
import LayoutSidenav from './LayoutSidenav'
import LayoutFooter from './LayoutFooter' */

export default {
  name: 'app-layout-1',
  /*  components: {
     'app-layout-navbar': LayoutNavbar,
     'app-layout-sidenav': LayoutSidenav,
     'app-layout-footer': LayoutFooter
   }, */
  data() {
    return {

    };
  },
  mounted() {
    this.layoutHelpers.init()
    this.layoutHelpers.update()
    this.layoutHelpers.setAutoUpdate(true)
  },

  beforeDestroy() {
    this.layoutHelpers.destroy()
  },

  methods: {
    closeSidenav() {
      this.layoutHelpers.setCollapsed(true)
    },
  }
}
</script>
